import React from "react";
import { graphql } from "gatsby";

import { Hero, LinkBox,LinkBoxProps } from "../../components";
import type { HeroProps } from "../../components/types";
import { DefaultLayout, SidebarLayout, SidebarBody } from "../../layouts";

export type InfoPageProps = {
  hero: HeroProps;
  body: string;
  sidebar: {
    boxes: LinkBoxProps[];
  };
};

export const InfoPageTemplate = ({ hero, body, sidebar }: InfoPageProps) => (
  <>
  <Hero {...hero} />
    <SidebarLayout
      body={
        <SidebarBody
        dangerouslySetInnerHTML={{
          __html: body,
        }}
        />
      }
      sidebar={
        sidebar &&
        sidebar.boxes.length > 0 &&
        sidebar.boxes.map((box: LinkBoxProps, index: number) => (
          <LinkBox {...box} key={index}/>
        ))
      }
    />
  </>
);

export const query = graphql`
  query($slug: String!) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        hero {
          showBox
          boxText
          image
        }
        sidebar {
          boxes {
            title
            color
            links {
              text
              url
              isExternal
            }
          }
        }
      }
    }
  }
`;

export default ({ data }) => (
  <DefaultLayout>
    <InfoPageTemplate body={data.page.html} {...data.page.frontmatter} />
  </DefaultLayout>
);
